<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <GrantsConstraintsPlansSlidDetailsForm
      v-if="!isLoading"
      :grantsConstraintsPlansSlidDetails="grantsConstraintsPlansSlidDetails"
      v-on:addOrUpdateGrantsConstraintsPlansSlidesDetails="
        addOrUpdateGrantsConstraintsPlansSlidesDetails
      "
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import GrantsConstraintsPlansSlidDetailsForm from "@/components/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidDetailsForm.vue";
import GrantsConstraintsPlansSlidDetails from "@/models/settings/settingsSalary/grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidDetails";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    GrantsConstraintsPlansSlidDetailsForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      grantsConstraintsPlansSlidDetails:
        new GrantsConstraintsPlansSlidDetails(),
      grantsConstraintsPlansSlidesDetailsToken:
        this.$route.params.grantsConstraintsPlansSlidesDetailsToken,
    };
  },
  // props: ["grantsConstraintsPlansSlidesDetailsToken"],
  methods: {
    async getGrantsConstraintsPlansSlidesDetailsDetails() {
      this.isLoading = true;
      try {
        let response =
          await this.grantsConstraintsPlansSlidDetails.getGrantsConstraintsPlansSlidesDetailsDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.grantsConstraintsPlansSlidDetails.fillData(
            response.data.grantsConstraintsPlansSlidesDetailsData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router
            .push({ name: "GrantsConstraintsPlansSlidesDetails" })
            .catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router
          .push({ name: "GrantsConstraintsPlansSlidesDetails" })
          .catch(() => {});
      }
      this.isLoading = false;
    },
    async addOrUpdateGrantsConstraintsPlansSlidesDetails() {
      this.isLoading = true;
      try {
        let response =
          await this.grantsConstraintsPlansSlidDetails.addOrUpdateGrantsConstraintsPlansSlidesDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router
            .push({
              name: "GrantsConstraintsPlansSlidesDetails",
              params: {
                grantsConstraintsPlansToken:
                  this.grantsConstraintsPlansSlidDetails
                    .grantsConstraintsPlansToken,
              },
            })
            .catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (
      this.grantsConstraintsPlansSlidesDetailsToken == "" ||
      this.grantsConstraintsPlansSlidesDetailsToken == undefined
    ) {
      this.$router.push({ name: "GrantsConstraintsPlans" }).catch(() => {});
    } else {
      this.grantsConstraintsPlansSlidDetails.grantsConstraintsPlansSlidesDetailsToken =
        this.grantsConstraintsPlansSlidesDetailsToken;
    }
    this.getGrantsConstraintsPlansSlidesDetailsDetails();
  },
};
</script>
